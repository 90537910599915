import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAdJFMUqj/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACAxMS/9oACAEBAAEFAttucdqBV9DUZypCoT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPwGH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERITJB/9oACAEBAAY/Ap6WlNSOUYj/xAAZEAADAQEBAAAAAAAAAAAAAAAAARExIXH/2gAIAQEAAT8hulYo5dJsExGmvUdW9tEEQkf/2gAMAwEAAgADAAAAEDAv/8QAFhEBAQEAAAAAAAAAAAAAAAAAERAx/9oACAEDAQE/EEZP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EMqP/8QAHBABAQACAgMAAAAAAAAAAAAAAREAMUFhIXGB/9oACAEBAAE/EIdbRXUCuISAHkPmHtap56xKsyUHOceyb950YAJn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "money",
          "title": "money",
          "src": "/static/7838791763315ccc45a6f3d22394a9d0/6aca1/money.jpg",
          "srcSet": ["/static/7838791763315ccc45a6f3d22394a9d0/d2f63/money.jpg 163w", "/static/7838791763315ccc45a6f3d22394a9d0/c989d/money.jpg 325w", "/static/7838791763315ccc45a6f3d22394a9d0/6aca1/money.jpg 650w", "/static/7838791763315ccc45a6f3d22394a9d0/7c09c/money.jpg 975w", "/static/7838791763315ccc45a6f3d22394a9d0/01ab0/money.jpg 1300w", "/static/7838791763315ccc45a6f3d22394a9d0/47e35/money.jpg 5397w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Chiffre d'Affaires à l’extérieur, Production à l'intérieur`}</h2>
    <p>{`Les notions de Chiffre d'Affaires, Production, Ventes ou Facturation sont utilisées comme quasi synonymes par beaucoup. `}</p>
    <br />
    <p><strong parentName="p">{`Le Chiffre d'Affaires`}</strong>{` quantifie l'aptitude globale de l'organisation à vendre et à livrer. Sans distinction entre une sortie du stock de l'an dernier ou d'une fabrication à la demande. C'est un bon indicateur de la capacité `}<strong parentName="p">{`commerciale`}</strong>{` globale de l'entreprise vue comme une boite noire, de sa présence sur les différents marchés.`}</p>
    <br />
    <p>{`Mais si on essaye de comprendre ce qui s'y passe ou l'origine de sa rentabilité il faut ouvrir la boite et utiliser l'indicateur le plus commensurable avec les `}<strong parentName="p">{`charges`}</strong>{` de l'entreprise (frais de personnel, achat de matières premières, ...):`}</p>
    <center>
      <p><strong parentName="p">{`La Production`}</strong>{`:`}</p>
      <blockquote>
        <p parentName="blockquote">{`tout ce qui a été produit par l'entreprise, avec son personnel, ses outils,`}<br parentName="p"></br>{`
`}{`sans considération de vente, facturation ou variation de stock.`}</p>
      </blockquote>
    </center>
    <br />
    <p><strong parentName="p">{`La Production`}</strong>{` est la grandeur qui porte le mieux `}<strong parentName="p">{`la signification`}</strong>{` de l'activité de l'entreprise et ses rouages.`}</p>
    <p>{`La Production est calculée comme étant la somme de tous les biens et services vendus sur la période ajustée des variations de stocks (on ne veut que ce qui a été fabriqué dans la période).`}</p>
    <h2>{`Le BON indicateur est COMPARABLE`}</h2>
    <p>{`Prenons une entreprise au hasard et deux de ses plus importantes grandeurs financières:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "534px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.901840490797547%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABeElEQVQY0z2OWW8SYRiF5//HK70xvTDGeoVxC22QFELdoGwz82X2nY9lhlWg1EHbwiOOiRdvcp6cc94cRbgDWrpLz4zoWTFdI6QtfNzBnMFshysXGOGEKN0g53cMFznJ9LZgS64IT/63tuDJ0+e8u6ihvK80eHZ2ztmrEudvypTKtUJ3jJjJ5pGWCLioNwkmW1R3xHfVwx+tEckKa7gmzHY4cknPluh+irLZH1nnB5a7e8xgiHATDF+iWiEd3aEjXPpmQN/wqdS/8uHyinZHp3di4URodkQ0XvDzAba/jigc9sBvOOTkq5RNNiCNHUaBSeIIYlsjNHpY/SahqSI9A+mbTCKbmQyYJt6pl7GejfmRDVHEl0tqb19y86mEuC7jtaqwDP/dOmYbayRqg8epWzCbBFZR4e/GLntpcMxsqqUXXH98jRJ0GzQrJbS/z26ukPrnovyQORxmHnOnTditc5863CY6TrPK3dBk6WvkY5vD3D/lvf8j/gApVah7OvsGigAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Soldes Intermédiaires de Gestion",
          "title": "Soldes Intermédiaires de Gestion",
          "src": "/static/0cbdee5c8e8fb43fbd2e367594d6375b/a07a7/SIG1.png",
          "srcSet": ["/static/0cbdee5c8e8fb43fbd2e367594d6375b/222b7/SIG1.png 163w", "/static/0cbdee5c8e8fb43fbd2e367594d6375b/ff46a/SIG1.png 325w", "/static/0cbdee5c8e8fb43fbd2e367594d6375b/a07a7/SIG1.png 534w"],
          "sizes": "(max-width: 534px) 100vw, 534px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`La `}<strong parentName="p">{`Production`}</strong>{` augmente de 50% pendant que le `}<strong parentName="p">{`Résultat`}</strong>{` lui fait +80% tout va bien! Les plus téméraires, qui sont souvent les moins forts en calcul, osent parfois même 80-50: `}<strong parentName="p">{`On a gagné 30 points de productivité !!`}</strong>{`... Il est urgent de les détromper.`}</p>
    <br />
    <p>{`Les indicateurs utiles, ceux qui éclairent la machine et son fonctionnement, partagent une caractéristique, ils sont:`}</p>
    <p><strong parentName="p">{`COMPARABLE DANS L'ESPACE ET DANS LE TEMPS`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`L'espace`}</strong>{`: entre magasins, clients, entre filiales, procédés de commercialisation, ou avec les concurrents, ...`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Le temps`}</strong>{`: se comparer à soi même, au trimestre précédent, à l'an dernier ou au plan de l'année prochaine. `}</p>
      </li>
    </ul>
    <br />
    <p>{`C'est plus facile quand l'indicateur est `}<strong parentName="p">{`sans dimension`}</strong>{`: un pourcentage, un ratio ou si la dimension est `}<strong parentName="p">{`à hauteur d'homme`}</strong>{` indépendante du volume: k€ par personne, par m2, ...`}</p>
    <br />
    <center>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`PAS COMPARABLE`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`MIEUX`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`CA (k€)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`CA/Personne`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Besoin de Trésorerie`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Besoin de Trésorerie en j de CA`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Valeur Ajoutée`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Valeur Ajoutée/Production`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Résultat net`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Rentabilité nette`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Dette Financière`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dette/Capital Économique`}</td>
          </tr>
        </tbody>
      </table>
    </center>
    <br />
    <p>{`La question intéressante pour comprendre la machine est: quelle `}<strong parentName="p">{`PROPORTION`}</strong>{` de `}<strong parentName="p">{`Production`}</strong>{` est transformée en `}<strong parentName="p">{`Résultat`}</strong>{` ? C'est un ratio comparable. Soit 100/1000 = 10% et 180/1500 =12%.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "534px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "6.748466257668712%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAAXElEQVQI1wFRAK7/ANHDuP+5rKH/xbes/87AtP/Uxbj/xbes/7yvpP/Nv7P/5dXH/9vLvv/559j/++na/+fWyP/25dj/8+PX//Lk2f/14NP//+zd/+XVyP/ayr7/i7hEs+/8rOEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Soldes Intermédiaires de Gestion",
          "title": "Soldes Intermédiaires de Gestion",
          "src": "/static/24f8bec37cf571cdb29cfe18a0cd1c3a/a07a7/SIG2.png",
          "srcSet": ["/static/24f8bec37cf571cdb29cfe18a0cd1c3a/222b7/SIG2.png 163w", "/static/24f8bec37cf571cdb29cfe18a0cd1c3a/ff46a/SIG2.png 325w", "/static/24f8bec37cf571cdb29cfe18a0cd1c3a/a07a7/SIG2.png 534w"],
          "sizes": "(max-width: 534px) 100vw, 534px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`  `}</p>
    <br />
    <p>{`La proportion `}<strong parentName="p">{`AUGMENTE de 2 points`}</strong>{` (c'est là que l'erreur d'appréciation de 30% fait sourire).`}</p>
    <br />
    <p>{`La question suivante est d'où vient cette augmentation: `}<strong parentName="p">{`POURQUOI 2% et d'où viennent ils`}</strong>{` ? `}</p>
    <h2>{`Le BON indicateur explique ses variations`}</h2>
    <p>{`Il faut ouvrir la boite qui transforme `}<strong parentName="p">{`Production`}</strong>{` en `}<strong parentName="p">{`Résultat`}</strong>{` pour faire apparaitre deux des étapes fondamentales qui s'y trouvent: `}</p>
    <ul>
      <li parentName="ul">{`La création de la `}<strong parentName="li">{`Valeur Ajoutée`}</strong>{`: ce qui reste après avoir acheté ce dont elle a besoin pour sa `}<strong parentName="li">{`Production`}</strong>{`. `}</li>
      <li parentName="ul">{`Son utilisation pour rémunérer les moyens de production (personnel, entretien de l'outillage, provisions pour risques)  `}</li>
    </ul>
    <br />
    <p>{`Pour fixer les idées prenons l'exemple d'un installateur de piscine. Pour réaliser une piscine il `}<strong parentName="p">{`achète`}</strong>{` les matériaux (parpaings, pompe, plongeoir, ciment, ...). Les `}<strong parentName="p">{`moyens`}</strong>{` étant les salariés qui travaillent sur le chantier en utilisant des véhicules, machines et outils qu'il faut acheter et entretenir. Et ce qui reste une fois payé les achats et les moyens est le `}<strong parentName="p">{`Résultat`}</strong>{` (positif ... ou négatif), qui est distribué aux actionnaires ou réinvesti dans l'entreprise.`}</p>
    <br />
    <p>{`Les équations sont simples: `}</p>
    <center>
      <blockquote>
        <p parentName="blockquote">{`PRODUCTION - ACHATS = VALEUR AJOUTEE`}</p>
        <p parentName="blockquote">{`VALEUR AJOUTEE - MOYENS = RESULTAT `}</p>
      </blockquote>
    </center>
    <br />
Examinons les chiffres successifs sur plusieurs années qui illustrent les variations mentionnées: Production + 50%, Résultat + 80%.
    <center>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.018404907975462%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsSAAALEgHS3X78AAAA1ElEQVQY002QW8tGQBSF/f+/43CFpJyJXCCnC+coRJSi9banvvqeyz171rNmOEEQYNs2VFWF7/sIwxDLsuC6Luz7DkVREAQBuq6DKIqY5xmWZUHXdazrCp7n0TQN4jiGLMvgDMNAWZbo+x5FUSDPcxzHgfd9QaRpypaf54HrukzUti0rQZimiWEYME0TK8M5joMkSVhgXdfMdt83/qiqikm+7wPtUjC1jKKInXuex15EMxJzkiSBWtKl/1AAQXMyE1mW4TxPjOPI2hIk2baNfY+mafgBKVsgfGehy84AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Soldes Intermédiaires de Gestion",
            "title": "Soldes Intermédiaires de Gestion",
            "src": "/static/61baafb8e266c9ad8afec8f213201c0c/a6d36/SIG31.png",
            "srcSet": ["/static/61baafb8e266c9ad8afec8f213201c0c/222b7/SIG31.png 163w", "/static/61baafb8e266c9ad8afec8f213201c0c/ff46a/SIG31.png 325w", "/static/61baafb8e266c9ad8afec8f213201c0c/a6d36/SIG31.png 650w", "/static/61baafb8e266c9ad8afec8f213201c0c/41d3b/SIG31.png 774w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
    </center>
    <br />
    <p>{`Mais où se trouve la performance ? `}<strong parentName="p">{`Où se trouve l'augmentation des 2 points ?`}</strong></p>
    <p>{`Introuvable dans les chiffres en €. La réponse se trouve dans les indicateurs exprimés en `}<strong parentName="p">{`pourcentage`}</strong>{`.`}</p>
    <p>{`Examinons les mêmes chiffres mais en pourcentage de la `}<strong parentName="p">{`Production`}</strong>{` pour la `}<strong parentName="p">{`Valeur Ajoutée`}</strong>{` et `}<strong parentName="p">{`Achats`}</strong>{` d'une part et en pourcentage de la `}<strong parentName="p">{`Valeur Ajoutée`}</strong>{` pour les `}<strong parentName="p">{`Moyens`}</strong>{` et le `}<strong parentName="p">{`Résultat`}</strong>{` d'autre part. Concentrons nous sur les valeurs initiales et finales.`}</p>
    <center>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "534px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.607361963190186%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABFUlEQVQY021QTcuCYBD0HyvixT/USVADS03xZKewyMCL3RLNLyz7Qqp52QUj6F2YyzPzzM6sYBgGXNfFYrFAEAQwTRNZloHm/X7je/b7PZIk+ZejIU7Y7XaI4xi32+1H+Hw+cb1e8Xg8cL/fUZYlqqrC6XTCdrvFarXiIJqmYTKZcBBhPp/D9302XS6XCMMQjuMw+Xq9cDgccDwe0fc9m53PZ2w2G8iyDFVVoSgKRFGEJEmsFUhEm2lrXddomoZBib5nGIZPWkJRFLxg/NO2Lb8LURQhTVPkef5zE0pI4q7rWDwakDk1oD8jKBTpBOrueR50Xcd6vf4Y0S0JVPFyuXBiqk6GZDCdTvlUs9kMlmXBtm3m/gALEbNqHzfiVAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Soldes Intermédiaires de Gestion",
            "title": "Soldes Intermédiaires de Gestion",
            "src": "/static/105c7a616e9b9d5c9db62aee6f3ae155/a07a7/SIG4.png",
            "srcSet": ["/static/105c7a616e9b9d5c9db62aee6f3ae155/222b7/SIG4.png 163w", "/static/105c7a616e9b9d5c9db62aee6f3ae155/ff46a/SIG4.png 325w", "/static/105c7a616e9b9d5c9db62aee6f3ae155/a07a7/SIG4.png 534w"],
            "sizes": "(max-width: 534px) 100vw, 534px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
    </center>
    <br />
    <p>{`La part des `}<strong parentName="p">{`Achats`}</strong>{` dans la `}<strong parentName="p">{`Production`}</strong>{` a baissé de 50% à 25%. Et dans le même intervalle la rémunération des moyens est passée de 80% de la `}<strong parentName="p">{`Valeur Ajoutée`}</strong>{` à 84%.`}</p>
    <p>{`Les 2% proviennent de la combinaison de ces deux mouvements: 75% x 16% = 12%. +2% au dessus du 10% initial.`}</p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`L'entreprise utilise 2 fois moins d'achats externes dans ses produits et prestations: changement de technologies, augmentation des prix, nouvelle gamme de produit, ... `}</p>
      <p parentName="blockquote">{`L'entreprise utilise plus de moyens ou des moyens plus couteux: nouvel outillage, personnel spécialisé mieux payé, ...`}</p>
    </blockquote>
    <br />
    <p>{`Ce type d'analyse est codifié par la discipline qui s'appelle `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Analyse_financi%C3%A8re"
      }}>{`l'Analyse Financière`}</a>{`. `}</p>
    <br />
    <p>{`N'hésitez pas à nous contacter pour en savoir davantage sur ce que ces techniques peuvent vous apporter pour différents objectifs: `}</p>
    <ul>
      <li parentName="ul">{`évaluation flash de l'entreprise (achat, transmission, ...)`}</li>
      <li parentName="ul">{`analyse de solvabilité`}</li>
      <li parentName="ul">{`diagnostic support de choix stratégiques et financiers`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      